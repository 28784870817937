import { AxiosPromise } from 'axios';
import {
	DetectionAngleSpecification,
	DetectionAreaSpecification,
	DetectionBrandSpecification,
	DetectionLogoOrTextSpecification,
	DetectionPlacementSpecification,
	DetectionPositionSpecification,
	DetectionTimeSpecification,
	DetectionPerspectiveSpecification,
} from 'Models/Project/ProjectFilterSpecifications';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PutChangeAllPlacementsRequest {
	placementId: number;
	angle: DetectionAngleSpecification | null;
	area: DetectionAreaSpecification | null;
	brand: DetectionBrandSpecification | null;
	logoOrText: DetectionLogoOrTextSpecification | null;
	placement: DetectionPlacementSpecification | null;
	position: DetectionPositionSpecification | null;
	time: DetectionTimeSpecification | null;
	perspective: DetectionPerspectiveSpecification | null;
}

const putChangeAllPlacementsRequest = (
	token: string | null,
	videoId: number,
	request: PutChangeAllPlacementsRequest
): AxiosPromise<void> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsEditorChangeAllPlacements).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.put(url, request);
};

export default putChangeAllPlacementsRequest;
