import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionAreaSpecification } from 'Models/Project/ProjectFilterSpecifications';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardAreaProps {
	area: DetectionAreaSpecification | null;
	onAreaChanged: (area: DetectionAreaSpecification | null) => void;
	selectedDetections: EditorVideoDetection[];
}

const INITIAL_AREA_VALUE: DetectionAreaSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardArea = (props: ChangeAllPlacementCardAreaProps): ReactElement => {
	const isChecked = props.area !== null;
	const fromValue = Math.round((props.area?.from ?? 0) * 10000) / 100;
	const toValue = Math.round((props.area?.to ?? 0) * 10000) / 100;
	const sliderValue = [fromValue, toValue];

	const getValueLabelFormat = (value: number): string => {
		return `${value}%`;
	};

	const marks = Array.from(Array(9).keys()).map((x) => ({
		value: x * 20,
		label: `${x * 20}%`,
	}));

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		const defaultValue = INITIAL_AREA_VALUE;
		if (props.selectedDetections.length > 0) {
			defaultValue.from = props.selectedDetections.reduce(
				(acc, x) => (x.area < acc ? x.area : acc),
				Number.MAX_SAFE_INTEGER
			);
			defaultValue.to = props.selectedDetections.reduce((acc, x) => (x.area > acc ? x.area : acc), 0);
		}
		if (checked) props.onAreaChanged(defaultValue);
		else props.onAreaChanged(null);
	};

	const setValues = (from: number, to: number): void => {
		props.onAreaChanged({ from: from / 100, to: to / 100 });
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) setValues(newValue[0], newValue[1]);
		else console.error(`Cannot set angle. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	const handleFromChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) setValues(value, toValue);
	};

	const handleToChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const value = Number(target.value);
		if (!isNaN(value)) setValues(fromValue, value);
	};

	return (
		<ChangeAllPlacementCard
			name="Select area"
			onCheckedChanged={handlePlacementCardCheckedChanged}
			isChecked={isChecked}
		>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Area
				</Typography>
				<Slider
					value={sliderValue}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					valueLabelFormat={getValueLabelFormat}
					min={0}
					max={100}
					step={0.1}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>From</InputLabel>
				<Input
					onChange={handleFromChanged}
					value={fromValue}
					required={true}
					inputProps={{
						step: 0.1,
						min: 0,
						max: 100,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>From</InputLabel>
				<Input
					onChange={handleToChanged}
					value={toValue}
					required={true}
					inputProps={{
						step: 0.1,
						min: 0,
						max: 100,
						type: 'number',
					}}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardArea;
