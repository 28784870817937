import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionPerspectiveSpecification } from 'Models/Project/ProjectFilterSpecifications';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardPerspectiveProps {
	perspective: DetectionPerspectiveSpecification | null;
	onPerspectiveChanged: (perspective: DetectionPerspectiveSpecification | null) => void;
	selectedDetections: EditorVideoDetection[];
}

const INITIAL_PERSPECTIVE_VALUE: DetectionPerspectiveSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardPerspective = (props: ChangeAllPlacementCardPerspectiveProps): ReactElement => {
	const isChecked = props.perspective !== null;
	const fromValue = props.perspective?.from ?? 0;
	const toValue = props.perspective?.to ?? 0;

	const marks = Array.from(Array(5).keys()).map((x) => ({
		value: x * 45,
		label: `${x * 45}°`,
	}));

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		const defaultValue = INITIAL_PERSPECTIVE_VALUE;
		if (props.selectedDetections.length > 0) {
			defaultValue.from = props.selectedDetections.reduce(
				(acc, x) => (x.perspective < acc ? x.perspective : acc),
				Number.MAX_SAFE_INTEGER
			);
			defaultValue.to = props.selectedDetections.reduce((acc, x) => (x.perspective > acc ? x.perspective : acc), 0);
		}
		if (checked) props.onPerspectiveChanged(defaultValue);
		else props.onPerspectiveChanged(null);
	};

	const setValues = (from: number, to: number): void => {
		props.onPerspectiveChanged({ from, to });
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) setValues(newValue[0], newValue[1]);
		else console.error(`Cannot set perspective. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	const handleFromChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const value = Number(target.value);
		if (!isNaN(value) && value <= toValue) setValues(value, toValue);
	};

	const handleToChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const value = Number(target.value);
		if (!isNaN(value) && value >= fromValue) setValues(fromValue, value);
	};

	return (
		<ChangeAllPlacementCard
			name="Select perspective"
			onCheckedChanged={handlePlacementCardCheckedChanged}
			isChecked={isChecked}
		>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Perspective
				</Typography>
				<Slider
					value={[fromValue, toValue]}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					min={0}
					max={180}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>From</InputLabel>
				<Input
					onChange={handleFromChanged}
					value={fromValue}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: toValue,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>To</InputLabel>
				<Input
					onChange={handleToChanged}
					value={toValue}
					required={true}
					inputProps={{
						step: 1,
						min: fromValue,
						max: 180,
						type: 'number',
					}}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardPerspective;
